<template>
  <v-text-field
    v-model="model"
    :label="label"
    :error-messages="errorMessages">

  </v-text-field>
</template>
<script>

export default {
  props: {
    value: {},
    label: {},
    errorMessages: {},
    type: {default: 'text'},
    delimiters: {default: () => {return /,| /}},
  },
  data () {
    return {
      model: this.value,
    }
  },
  methods: {
    cleanValue (v) {
      return v.split(this.delimiters).map((r) => {
        return r.trim()
      }).filter((r) => {
        return !!r
      })
    }
  },
  watch: {
    model (v) {
      this.$emit('input', this.cleanValue(v))
    }
  }
}
</script>